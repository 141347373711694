(function () {
    $(document).on('click', '.circular-menu-item-link, .circular-menu .floating-btn', function () {
        //open close menu
        $(this).closest('.circular-menu').toggleClass('active');
        //toggle overlay
        $("#overlay").toggle();
    })


    $(window).resize(checkOverlay);

    function checkOverlay() {
        if (typeof window.matchMedia == 'function'
            && !matchMedia('(max-width: 1199.98px)').matches) {
            $("#overlay").css("display", "none");
            $("#circularMenu").removeClass("active");
        }
    }
})();