//misc workarounds for stupid modal-background behavior
(function ($) {
    var modals = { locked: false };
    var lockWaitMillis = 25;
    var animationWaitMillis = 1000;

    //Gets called immediately after initiating the modal close but
    //ONLY if the modal close is done the Bootstrap way. Just deleting
    //the modal from the DOM does not cause this method to get called.
    $(document).on("hide.bs.modal", ".modal", onHide);

    function onHide(e) {
        //This actually gets called for other things like the 
        //bootstrap datepicker, so we need to make sure this
        //is a modal that's being hidden.
        if ($(e.target).is('.modal')) {
            if (!modals.locked) {
                modals.locked = true;
                var backdrops = $(".modal-backdrop");
                modals[e.target] = backdrops;
                modals.locked = false;
                window.setTimeout(function () { onHidden(e); }, animationWaitMillis);
            } else {
                window.setTimeout(function () { onHide(e); }, lockWaitMillis);
            }
        }
    }

    //Gets called after the animation for closing the modal has finished. This
    //method will only get called if the onHide method above is called. The bootstrap
    //event will only be triggered if the modal still exists in the DOM when the 
    //animation timer completes. As a workaround for when the modal is deleted from 
    //the DOM prior to the animation timer completing, this method should also be
    //called from onHide via a timer.
    $(document).on("hidden.bs.modal", ".modal", onHidden);
    function onHidden(e) {
        if (!modals.locked) {
            modals.locked = true;
            var backdrops = modals[e.target];
            if (backdrops && backdrops.length) {
                backdrops.remove();
            }
            modals[e.target] = null;
            if (!$(".modal:visible").length) {
                $(document.body).removeClass("modal-open");
            }
            modals.locked = false;
        } else {
            window.setTimeout(function () { onHidden(e); }, lockWaitMillis);
        }
    }

    //Run a timer checking to see if modal backdrops exist when no modals are visible.
    //If that situation occurs more than maxTimerFails times then delete
    //all the modal backdrops. This method largely makes the other two unnecessary,
    //but the other two are much more responsive to user input which is why I'm
    //leaving the others (keep in mind that jQuery :visible is true until the modal fade
    //animation is finished).
    (function () {
        var maxTimerFails = 20;
        var failCount = 0;
        var timerMillis = 250;
        function onTimer() {
            if (!$(".modal.show").length && $(".modal-backdrop").length) {
                failCount++;
            } else {
                failCount = 0;
            }

            if (failCount >= maxTimerFails) {
                $(".modal-backdrop").remove();
                $(document.body).removeClass("modal-open");
                console.log('ERROR: Workaround timer forced to clear modal-backdrop. Please close your modals properly by calling $(elem).modal("hide").');
            }
        }
        var timer = window.setInterval(onTimer, timerMillis);
    })();
})(jQuery);