(function () {
    Titania.Models.Cookie = function () {
	}

	// Gets a value indicating whether the cookie exists.
	Titania.Models.Cookie.DoesCookieExist = function (cname) {
		return !!Titania.Models.Cookie.GetCookie(cname);
    }

    // Gets a cookie value with the given cookie name.
    // Returns empty string if the find fails. 
	Titania.Models.Cookie.GetCookie = function (cname) {
		var name = cname + "=";
		var decodedCookie = decodeURIComponent(document.cookie);
		var ca = decodedCookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}

		return "";
	}

	// Sets a cookie with the given key and value
	Titania.Models.Cookie.SetCookie = function (cname, cvalue, path) {
		var c = cname + "=" + cvalue;
		if (path) {
			c += "; path=" + path
		}

		document.cookie = c;
    }
})();