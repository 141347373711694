(function () {
    //An ajax-form uses jQuery ajax to submit, and uses conventions to make creating ajax
    //forms quick and easy. It's also compatible w/ jQuery validation.

    //To make an ajax-form, create a form and add the ajax-form class
    //<form class="ajax-form">

    //When you take an action that would normally cause the form to be
    //submitted (e.g. clicking a submit button or pressing enter) the
    //ajax-form will make an ajax call to submit the form instead.

    //When the submit process begins, all the form inputs will become 
    //disabled, and when the submit process is finished the inputs
    //will be un-disabled.

    $(document).on("click", ".ajax-form [type=submit]", function (e) {
        e.preventDefault();
        var $this = $(this);
        submitForm($this.closest('.ajax-form'), { name: $this.prop('name'), value: $this.prop('value') });
    })
    
    $(document).on("submit", "form.ajax-form", function (e) {
        e.preventDefault();
        submitForm(this);
    });

    function submitForm(form, submitButtonData) {
        var $form = $(form);

        if (!$form.data('isLocked')) {
            if (submitButtonData) {
                $form.append('<input type="hidden" name="' + submitButtonData.name + '" value="' + submitButtonData.value + '" class="submitButtonData" />');
            }
            var data = $form.serialize();
            $form.find('.submitButtonData').remove();
            lockForm($form);

            $.ajax({
                url: $form.prop('action'),
                type: 'POST',
                contentType: 'application/x-www-form-urlencoded',
                data: data,
                success: function (data) {
                    $form.find('.ajax-form-data').add($form.data('ajax-form-target')).html(data);
                    var successCallback = $form.data('ajax-form-success');
                    if (successCallback) {
                        //successCallback can either be a function that receives the data
                        //or a JS expression to be executed.
                        var func = findFunc(successCallback);
                        if (func) {
                            func(data);
                        } else {
                            eval(successCallback);
                        }
                    }
                },
                complete: function () {
                    unlockForm($form);
                }
            });
        }
    }

    function lockForm($form) {
        $form.data('isLocked', new Date());
        $form.find('.ajax-form-loading').add($form.data('ajax-form-loading')).addClass('active');
        $form.find(':input:not(:disabled)').addClass('ajax-form-disabled').prop('disabled', 'disabled');
    }

    function unlockForm($form) {
        $form.removeData('isLocked')
        $form.find('.ajax-form-loading').add($form.data('ajax-form-loading')).removeClass('active');
        $form.find('.ajax-form-disabled').removeAttr('disabled').removeClass('ajax-form-disabled');
    }

    function findFunc(funcName) {
        var pieces = funcName.split('.');
        if (pieces.length > 0) {
            var context = window;
            var func = null;
            for (var i = 0; i < pieces.length - 1; i++) {
                var newContext = context[pieces[i]];
                if (newContext) {
                    context = newContext;
                } else {
                    context = window;
                    break;
                }
            }

            func = context[pieces[pieces.length - 1]];
            if (typeof func == "function") {
                return func.bind(context);
            }
        }
    }
})();