var contactUsModalJS = (function () {
    var initialized = false;

    return {
        Init: function () {
            if (initialized) {
                return;
            }

            function checkTopOfContactModal() {
                if (typeof window.matchMedia == 'function'
                    && matchMedia('(max-width: 1199.98px)').matches) {
                    //Need to accomodate for height of OE banner
                    $("#supportModal").find(".modal-dialog").css("top", $(".header-wrapper").height());
                    $("#contactModal").find(".modal-dialog").css("top", $(".header-wrapper").height());
                }
            }

            checkTopOfContactModal()

            $(window).resize(checkTopOfContactModal);

            $("#supportModalToggle").click(function () {
                $("#contactModal").modal('toggle');
                $("#supportModal").modal('toggle');
            });

            $("#goBackToContactModal").click(function () {
                $("#supportModal").modal('toggle');
                $("#contactModal").modal('toggle');
            });

            //Check if all fields are filled out
            $('#sendEmailButton').prop("disabled", true);

            $(document).on('keyup', '.contact-us-input', function (e) {
                $('#sendEmailButton').prop("disabled", !!$('.modal.show').find(".contact-us-input").filter(function () { return this && (!this.value || !this.value.trim()); }).length);
            })

            $('#sendEmailButton').click(function (e) {
                e.preventDefault();
                if ($(this).prop('disabled') == true) {
                    return;
                }
                $(this).prop('disabled', true);

                var contactFormViewModel = {};

                $.each($(this).closest('form').serializeArray(), function () {
                    contactFormViewModel[this.name] = this.value || '';
                });

                $.ajax({
                    type: 'POST',
                    url: url,
                    data: JSON.stringify(contactFormViewModel),
                    contentType: 'application/json'
                }).done(function () {
                    $("#supportModal").modal('toggle');
                    $('.contact-us-input').val('');
                    $('#sendEmailButton').prop("disabled", true);
                });
            });

            initialized = true;
        }
    }
})();