//Prevent multiple clicks on elements with the single-click class.
//Once an element is no longer clickable, you can make it
//clickable again by calling $(element).data('clicked', false).
//This double-click protection doesn't prevent non-delegated
//event listeners or listeners delegated lower than body
//from running. This code is intended for anchors, but 
//should work for buttons also, it is NOT good enough
//for forms. To do this for forms you should intercept
//the submit event and disable the fields on the form.
(function () {
    $(document).on("click", ".single-click", function (e) {
        if ($(this).data('clicked')) {
            e.preventDefault();
            e.stopPropagation();
            return false;
        }

        $(this).add(getClickGroup(this)).data('clicked', true);
    });

    function getClickGroup(e) {
        return $(getClickGroupClass(e));
    }

    function getClickGroupClass(e) {
        var classes = $(e).prop('class').split(/\s+/);
        for (var i = 0; i < classes.length; i++) {
            if (classes[i].indexOf('click-group-') == 0) {
                return '.' + classes[i];
            }
        }
    }
})();