(function () {
    Titania.Models.PhoneNumber = function () {
    }

    Titania.Models.PhoneNumber.Format = function (phone) {
        phone = (phone || '').replace(/\D/g, '');
        var result = "";
        for (var i = 0; i < phone.length; i++) {
            if (i === 0) {
                result = "(" + phone[i];
            }
            else if (i === 2) {
                result = result + phone[i] + ") ";
            }
            else if (i === 5) {
                result = result + phone[i] + "-";
            }
            else {
                result = result + phone[i];
            }
        }
        return result;
    }
})();