(function () {
    Titania.Models.Boolean = function () {
    }

    //Parse a boolean from the given string.
    //Returns undefined if the parse fails. You can
    //check for success by seeing if typeof the return
    //value is boolean.
    Titania.Models.Boolean.Parse = function (s) {
        if (typeof (s) == "string") {
            s = s.toLowerCase();
            if (s == "t" || s == "true") {
                return true;
            } else if (s == "f" || s == "false") {
                return false;
            }
        } else if (typeof (s) == "boolean") {
            return s;
        }
    }
})();