(function () {
    showErrorModal = function (model) {
        if (typeof model == "string") {
            model = JSON.parse(model);
        }

        for (var k in model) {
            $(".error-popup-" + k).text(model[k]);
        }

        $(".error-popup, .error-popup-backdrop").addClass('active');
    }

    hideErrorModal = function () {
        $(".error-popup, .error-popup-backdrop").removeClass('active');
    }

    $(document).on("click", '#copyErrorButton', function () {
        const el = document.getElementsByClassName('error-popup-data-copy')[0];
        var range = document.createRange();
        range.selectNodeContents(el);
        var sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
        document.execCommand('copy');
    });

    $(document).on("click", ".error-popup-close", hideErrorModal);
})();
