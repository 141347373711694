(function () {
    //dateTimeOffSet is set in the head of the layout page
    //so this should never actually happen.
    if (typeof (dateTimeOffSet) == 'undefined') {
        dateTimeOffSet = 0;
    }

    // handle to old date function.
    var date = Date;

    Date = function () {
        var d = null;
        if (arguments.length == 0) {
            d = new date();
            d.setDate(d.getDate() + dateTimeOffSet);
        }
        else if (arguments.length == 1)
            d = new date(arguments[0]);
        else
            d = new date(arguments[0], arguments[1], arguments[2] || 0, arguments[3] || 0, arguments[4] || 0, arguments[5] || 0, arguments[6] || 0);
        if (this instanceof Date)
            return d;
        return d.toString();
    };

    Date.prototype = date.prototype;

    Date.prototype.constructor = Date;

    Date.parse = function () {
        return date.parse.apply(date, arguments);
    }

    Date.now = function () {
        return date.now() + dateTimeOffSet * 24 * 60 * 60 * 1000;
    }

    Date.UTC = function () {
        return date.UTC.apply(date, arguments);
    }
})();