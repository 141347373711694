(function () {


    var completedDate = false;
    var completedType = false;

    var booleanParse = function (s) {
        if (typeof (s) == "string") {
            s = s.toLowerCase();
            if (s == "t" || s == "true") {
                return true;
            } else if (s == "f" || s == "false") {
                return false;
            }
        } else if (typeof (s) == "boolean") {
            return s;
        }
    }

    $(window).ready(function () {

        $("a[href='#LEQuestionModal']").click(function () {
            var transactionType = $(this).data('transtype');

            if (transactionType && transactionType.toLowerCase() == 'lifeevent') {
                $("#lifeEventDetails").show();
                $(".oeDisplay").hide();
            }
            else if (transactionType && transactionType.toLowerCase() == 'openenrollment') {
                $("#lifeEventDetails").hide();
                $(".oeDisplay").show();
            }
        });

        var hasMultipleLifeEvents = booleanParse($("#HasMultipleLifeEvents").val());
        $("#lifeEventDetails").hide();
        $(".oeDisplay").show();

        if (hasMultipleLifeEvents) {
            $("#lifeEventDetails").show();
            $(".oeDisplay").hide();
        }

        $("#leModalDateError").text("");

        $("#editLifeEvent").click(function () {
            $("#lifeEventDetails").show();
        });
        //set the continue button to disabled and clear out any LE values in local storage
        checkEnableEnrollmentBtn();
        localStorage.removeItem("selectedLifeEventType");
        localStorage.removeItem("selectedLifeEventDate");

        $('#lifeEventDatePicker').datepicker().on('changeDate', function (e) {
            //get the date and format it
            $("#leModalDateError").text("");

            var parsedDateSelected = getParsedDateSelected();
            
            var dateSelected = parsedDateSelected.getMonth() + 1 + '/' + parsedDateSelected.getDate() + '/' + parsedDateSelected.getFullYear();

            //any time the datepicker field changes check if the continue button can be enabled
            if (dateSelected != "") {
                leDate = dateSelected;
                completedDate = true;
                checkEnableEnrollmentBtn();
            }

        });

        $('#lifeEventDatePicker').datepicker().on('keyup', function (e) {
            //get the date and format it
            if ($("#lifeEventDate").val().replace(/ /g, '').length == 10) {
                var parsedDateSelected = getParsedDateSelected();

                var dateSelected = parsedDateSelected.getMonth() + 1 + '/' + parsedDateSelected.getDate() + '/' + parsedDateSelected.getFullYear();

                //any time the datepicker field changes check if the continue button can be enabled
                if (dateSelected != "") {
                    leDate = dateSelected;
                    completedDate = true;
                    checkEnableEnrollmentBtn();
                }
            }
        });

       
        $(".lifeEventOption").click(function () {
            //properly set radio button classes
            var selectedType = 0;
            var classNames = this.classList;

            //Finding selected type
            for (var i = 0; i < classNames.length; i++) {
                if (classNames[i].indexOf("leType-") !== -1) {
                    selectedType = classNames[i].replace("leType-", "");
                }
            }

            //Displaying main text area
            if (selectedType && parseInt(selectedType) && selectedType != 0) {
                $("#SelectedLifeEventTypeId").val(parseInt(selectedType));

                completedType = true;

                var isMobile = typeof window.matchMedia == 'function' && matchMedia('(max-width: 1199.98px)').matches;
                var containerName = isMobile ? ".service-modal-le-container-mobile" : ".service-modal-le-container-desktop";

                if (selectedType == '8' || selectedType == '11' || selectedType == '12') {
                    if (isMobile) {
                        $(containerName).hide();
                        $(this).find(containerName).show();
                    }
                    else {
                        $(containerName).show();
                    }
                }
                else {
                    $(containerName).hide();
                }

            }



            //Toggling Check Mark Portion
            $(".lifeEventOption").each(function () {
                if ($(this).hasClass("leType-" + selectedType)) {
                    $(this).find("i.le-modal-le-type").addClass("fas fa-check-circle").removeClass("far fa-circle");
                }
                else {
                    $(this).find("i.le-modal-le-type").addClass("far fa-circle").removeClass("fas fa-check-circle");
                }
            });

            checkEnableEnrollmentBtn();
        });


        $(".closeLeModal").click(function () {
            leDate = null;
            leType = null;
            completedDate = false;
            completedType = false;
            if (!hasMultipleLifeEvents) {
                $("#lifeEventDetails").hide();
            }
        });

        checkTopOfLEModal();
    });

    function checkTopOfLEModal() {
        if (typeof window.matchMedia == 'function'
            && matchMedia('(max-width: 1199.98px)').matches) {
            //Need to accomodate for height of OE banner
            $("#LEQuestionModal").css("top", $(".header-wrapper").height());
        } else {
            $("#LEQuestionModal").css("top", "0");
        }
    }

    function checkEnableEnrollmentBtn() {
        updateTheContinueButton(true);

        validateLifeEventDate();

        if ($("#leModalDateError").text() != "") {
            updateTheContinueButton(true);
            return;
        }

        if (completedDate && completedType) {
            updateTheContinueButton(false);
        }
    }

    function getParsedDateSelected() {
        return $('#lifeEventDatePicker').datepicker('getDate');
    }


    function validateLifeEventDate() {

        if (!completedDate || !completedType) {
            return;
        }

        var parsedDateSelected = getParsedDateSelected();

        var todaysDate = new Date();
        todaysDate.setHours(0, 0, 0, 0);

        if (todaysDate < parsedDateSelected) {
            $("#leModalDateError").html("<i class='fas fa-exclamation-circle' aria-hidden='true'></i><span>You cannot report a future life event. Please come back to report your life event on or after the date it occurs.</span>");
        }

        var enrollmentWindow = getEnrollmentWindowForSelectedLifeEvent();
        var minDate = new Date(todaysDate);
        minDate.setDate(todaysDate.getDate() - enrollmentWindow);

        if ((minDate) > parsedDateSelected) {
            $("#leModalDateError").html("<i class='fas fa-exclamation-circle' aria-hidden='true'></i><span>Life event must have occurred within the past " + enrollmentWindow + " days.</span>");
        }

    }

    function getEnrollmentWindowForSelectedLifeEvent() {
        return $(".leTypeWindow-" + $("#SelectedLifeEventTypeId").val()).val();
    }

    function updateTheContinueButton(isDisabled) {
        $("#continueToEnrollmentBtn").prop("disabled", isDisabled);

        if (isDisabled) {
            $("#continueToEnrollmentBtn").removeClass('next-btn');
            $("#continueToEnrollmentBtn").addClass('confirm-btn');
        }
        else {
            $("#continueToEnrollmentBtn").removeClass('confirm-btn');
            $("#continueToEnrollmentBtn").addClass('next-btn');
        }
    }

    //On resize check to see where the text area should be displaying
    $(window).on('resize', function () {
        var checkedCircle = $('.le-modal-le-type.fa-check-circle');
        //Check if any LE is checked on resize
        if (checkedCircle.length > 0) {

            if (checkedCircle.closest('.leType-8').length || checkedCircle.closest('.leType-11').length || checkedCircle.closest('.leType-12').length) {
                //Display Text Area in Desktop
                if (typeof window.matchMedia == 'function' && matchMedia('(min-width: 1199.98px)').matches) {
                    $(".service-modal-le-container-desktop").show();
                    $(".service-modal-le-container-mobile").hide();
                } else {
                    //Display mobile text area
                    $(".service-modal-le-container-desktop").hide();
                    $(".service-modal-le-container-mobile").hide();
                    $('.le-modal-le-type.fa-check-circle').closest(".life-event-modal-option").find('.service-modal-le-container-mobile').show();
                }
            }
        } else {
            $(".service-modal-le-container-desktop").hide();
            $(".service-modal-le-container-mobile").hide();
        }

        checkTopOfLEModal();
    });

})();