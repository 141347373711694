(function () {

	var platform = {};

	// browser info and capability
	var _ua = window.navigator.userAgent;

	platform.isIDevice = (/iphone|ipod|ipad/i).test(_ua);
	platform.isAndroid = /android/i.test(_ua);
	platform.isSamsung = /Samsung/i.test(_ua);
	platform.isFireFox = /Firefox/i.test(_ua);
	platform.isOpera = /opr/i.test(_ua);
	platform.isEdge = /edg/i.test(_ua);
	platform.isKindleFire = /Kindle|Silk|KFAPW|KFARWI|KFASWI|KFFOWI|KFJW|KFMEWI|KFOT|KFSAW|KFSOWI|KFTBW|KFTHW|KFTT|WFFOWI/i.test(_ua);

	// Opera & FireFox only Trigger on Android
	platform.isFireFoxForAndroid = platform.isFireFox && platform.isAndroid;
	platform.isOperaForAndroid = platform.isOpera && platform.isAndroid;

	platform.isChromium = ("onbeforeinstallprompt" in window);
	platform.isInWebAppiOS = (window.navigator && window.navigator.standalone === true);
	platform.isInWebAppChrome = (window.matchMedia && window.matchMedia('(display-mode: standalone)').matches);
	platform.isMobileSafari = platform.isIDevice && _ua.indexOf('Safari') > -1 && _ua.indexOf('CriOS') < 0;
	platform.isStandalone = platform.isInWebAppiOS || platform.isInWebAppChrome;

	platform.isiPad = (platform.isMobileSafari && _ua.indexOf('iPad') > -1)
		|| (navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform));

	platform.isiPhone = platform.isMobileSafari && _ua.indexOf('iPad') === -1;
	platform.isCompatible = platform.isChromium
		|| platform.isMobileSafari
		|| platform.isSamsung
		|| platform.isFireFox
		|| platform.isOpera;

	var getIosVersion = function () {
		var result = {
			major: 0,
			minor: 0,
			revision:0
        }

		if (platform.isiPad || platform.isiPhone) {
			const extract = _ua.match(/OS (\d+)_(\d+)_?(\d+)?/);
			result.major = parseInt(extract[1] || 0, 10);
			result.minor = parseInt(extract[2] || 0, 10);
			result.revision = parseInt(extract[3] || 0, 10);
		}

		return result;
	}

	$(document).ready(function () {
		if (!Titania.Models.Cookie.DoesCookieExist('a2hsOptedOut')) {
			Titania.Models.Cookie.SetCookie("a2hsOptedOut", false, '/'); // has the user opted out
		}

		$('.pwa-install-close-button').click(function () {
			$('.error-popup-backdrop').removeClass('pwa-backdrop active');
			$('.pwa-install-footer').hide();
			Titania.Models.Cookie.SetCookie("a2hsOptedOut", true, '/');
		});

		var iosVersion = getIosVersion();

		if ((platform.isiPad || platform.isiPhone)
			&& (iosVersion.major >= 14 || iosVersion.major == 13 && iosVersion.minor >= 7)
			&& !platform.isInWebAppiOS
			&& !Titania.Models.Boolean.Parse(Titania.Models.Cookie.GetCookie('a2hsOptedOut'))) {

			setTimeout(function () {
				$('.error-popup-backdrop').addClass('pwa-backdrop active');
				$('.pwa-install-footer').show();
			}, 2000);
		}
		else {
			// only show the installer tutorial video for iOS devices
			$('.pwa-video-btn').hide();
		}

		$('.pwa-video-btn').click(function () {
			$('.pwa-install-video-container').show();

			// replay the gif
			var srcAttribute = $('.pwa-install-video-container > img')
				.attr('src')
				.split('?')[0] + '?v=' + Date.now();

			$('.pwa-install-video-container > img').attr('src', srcAttribute);
		});

		$('.pwa-install-video-container-close').click(function () {
			$('.pwa-install-video-container').hide();
		});
	});

	window.addEventListener("beforeinstallprompt", function (e) {
		// cancel prompt if running on kindle fire
		if (platform.isKindleFire) {
			console.log('i am KindleFire');
			e.preventDefault();
        }
	});

	if ('serviceWorker' in navigator) {
		window.addEventListener('load', function () {
			navigator.serviceWorker.register('/PWAServiceWorker.js').then(function (registration) {
				// Registration was successful
				console.log('ServiceWorker registration successful with scope: ', registration.scope);
			}, function (err) {
				// registration failed :(
				console.log('ServiceWorker registration failed: ', err);
			});
		});
	}
})();