var dashboardMobileMenuModule = (function () {

    //This variable allows you to click the same active menu you're on when the page loads. Without this you would have to click on a different menu to be able to get to the main menu due to the event stop propgation not allowing the menus to close.
    var mainLoadedMenuClicked = false;

    var openMenu = function (e) {
        if ($(e.target).hasClass("sub-menu-links-collapsible")) {
            $(e.target).parent().next(".sub-menu-links-collapsible-content").toggle();
            //Make sure main dropdown overlay doesn't close
            e.stopPropagation();
        }
        else if (mainLoadedMenuClicked && $(this).hasClass('active')) {
            e.stopPropagation();
        } else {
            if (!$(this).find('a').attr('href')) {
                $(".mobile-navbar").find('.menu-item-mobile').removeClass("active");
                $(this).addClass("active");
            }
            else {
                e.stopPropagation();
            }
        }
        mainLoadedMenuClicked = true;
    }

    return {
        Init: function () {
            //Toggle active mobile menu on click
            $(".menu-item-mobile").click(openMenu);

            $(".dashboard-mobile-menu a").click(function (e) {

                var href = $(this).attr('href');

                if (!href) {
                    $(".menu-item-mobile").removeClass('show');
                    var menuItemID = $(this).data('menu-item-id');
                    var bottomNavMenuButton = $(".menu-item-mobile[data-menu-item-id='" + menuItemID + "']");

                    if (bottomNavMenuButton.length) {
                        $(bottomNavMenuButton).click();
                        bottomNavMenuButton.addClass('show');
                    }
                    else {
                        // menu is under the "MORE" section.                        
                        bottomNavMenuButton = $(".menu-item-mobile[data-menu-item-id='more-button']");
                        $(bottomNavMenuButton).click();
                        bottomNavMenuButton.addClass('show');

                        //open menu under "MORE"
                        $("[data-target='#collapse-" + menuItemID + "']").click();
                    }

                    $('.mobile-navbar').show();
                    e.stopPropagation();
                }
            });
        }
    }
})();