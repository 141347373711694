//Customizations to jQuery Ajax
(function ($) {
    var ajax = $.ajax;

    $.ajax = function (url, opts) {
        //The 1st arg is probably the options object, but it could be the URL,
        //see http://api.jquery.com/jquery.ajax/
        opts = $.extend(true, {}, typeof (url) == "string" ? opts : url);

        if (typeof opts.error != 'function') {
            opts.error = function (jqXHR, textStatus, errorthrown) {
                showErrorModal(jqXHR.responseText);
            }
        }

        return ajax.call($, opts);
    }
})(jQuery);